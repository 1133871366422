import { RegexPresets } from "@/uikit/utils/validators/regex";
import { enUsLocaleName, ukUaLocaleName } from "@/utils/constants";
import Joi from "joi";

export const PHONE_NUMBER_MAX_LENGTH = 15;

export const PHONE_NUMBER_REGEX = /^\+\d{8,15}$/;

export const TTN_NUMBER_REGEX = /^[0-9]*$/;

const passwordErrMsgs = {
    [ukUaLocaleName]: {
        "string.empty": "Пароль має бути мінімум 3 символи.",
        "string.max": "Пароль не може містити більше 100 символів.",
        "string.min": "Пароль має бути мінімум 3 символи.",
        "string.pattern.base": "Пароль має містити мінімум 8 символів, 1 велику і 1 малу літеру.",
    },
    [enUsLocaleName]: {
        "string.empty": "The password must be at least 3 characters long.",
        "string.max": "The password cannot exceed 100 characters.",
        "string.min": "The password must be at least 3 characters long.",
        "string.pattern.base": "The password must contain at least 8 characters, 1 uppercase letter, and 1 lowercase letter.",
    },
};

const confirmPasswordErrMsgs = {
    [ukUaLocaleName]: {
        "string.empty": "Пароль має бути мінімум 3 символи.",
        "string.max": "Пароль не може містити більше 100 символів.",
        "string.min": "Пароль має бути мінімум 3 символи.",
        "any.required": 'Значення цього поля має бути однакове із полем "пароль".',
        "any.only": 'Значення цього поля має бути однакове із полем "пароль".',
    },
    [enUsLocaleName]: {
        "string.empty": "The password must be at least 3 characters long.",
        "string.max": "The password cannot exceed 100 characters.",
        "string.min": "The password must be at least 3 characters long.",
        "any.required": 'This field value must match the "password" field.',
        "any.only": 'This field value must match the "password" field.',
    },
};

const emailErrMsgs = {
    [ukUaLocaleName]: {
        "string.pattern.base": "Введіть дійсну адресу електронної пошти у форматі example@domain.com",
        "string.empty": "Поле електронної пошти не може бути порожнім.",
        "any.required": "Електронна пошта є обов'язковою.",
    },
    [enUsLocaleName]: {
        "string.pattern.base": "Please enter a valid email address in format example@domain.com",
        "string.empty": "Email field cannot be empty.",
        "any.required": "Email is required.",
    },
};

const phoneNumberErrMsgs = {
    [ukUaLocaleName]: {
        "string.pattern.base": "Введіть дійсний номер телефону у форматі +380991234567.",
        "string.empty": "Поле номеру телефону не може бути порожнім.",
        "any.required": "Номер телефону є обов'язковим.",
    },
    [enUsLocaleName]: {
        "string.pattern.base": "Please enter a valid phone number in format +380991234567.",
        "string.empty": "Phone number field cannot be empty.",
        "any.required": "Phone number is required.",
    },
};

const numberFieldErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": "Значення має бути числом.",
        "number.min": "Число має бути більше або дорівнювати 0.",
        "number.precision": "Число може містити максимум 3 десяткові знаки.",
    },
    [enUsLocaleName]: {
        "number.base": "The value must be a number.",
        "number.min": "The number must be greater than or equal to 0.",
        "number.precision": "The number can have up to 3 decimal places.",
    },
};

export const passwordSchema = Joi.string().pattern(RegexPresets.newPassword).messages(passwordErrMsgs);
export const emailSchema = Joi.string().regex(RegexPresets.email).messages(emailErrMsgs);
export const phoneNumberSchema = Joi.string().regex(PHONE_NUMBER_REGEX).messages(phoneNumberErrMsgs);
export const filterNumber = Joi.number().min(0).precision(3).optional().allow("").messages(numberFieldErrMsgs);

export const updatePasswordSchema = Joi.object({
    password: passwordSchema,
    confirmPassword: Joi.when("password", {
        is: passwordSchema.required(),
        then: Joi.valid(Joi.ref("password")).required(),
        otherwise: Joi.allow(""),
    }).messages(confirmPasswordErrMsgs),
});

export const referenceSchema = { id: Joi.number().optional().allow(null), presentation: Joi.string().optional() };
