import { SavedFile } from "@/services/types";

export type SomeFieldsRequest = {
    fields?: string[];
};

export interface EditedPhoto extends Partial<SavedFile> {
    file?: File;
}

export type Locale = {
    en: "en";
    ua: "ua";
};

export class EntityReference {
    id?: number;
    presentation?: string;
    constructor(id?: number, presentation?: string) {
        this.id = id;
        this.presentation = presentation;
    }
}
