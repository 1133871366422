const deals = {
    title: "Угоди",
    subtitle: "Відслідковуй найголовніше на Дешборді",
    table: {
        title: "Інформація про угоди",
        emptyMessage: "Таблиця не містить даних",
        emptySearchResult: "Даних не знайдено за вашим запитом",
        panel: {
            searchPlaceholder: "Пошук",
            archive: "Архів",
            create: "Створити угоду",
            filter: "Фільтр",
        },
        columns: {
            id: "ID",
            name: "Назва",
            additionalSpendings: "Додаткові витрати",
            totalAmount: "Сума угоди",
            currency: "Валюта",
            client: "Клієнт",
            responsible: "Відповідальний",
            comment: "Коментар",
            stage: "Етап",
            source: "Джерело",
            createdAt: "Створено",
            endDate: "Дата завершення",
            actions: "Дії",
            responsibleContact: "Відповідальний контакт",
            funnel: "Воронка",
            conversionTime: "Час конверсії",
            novaPostDeliveryAddress: "Адреса нової пошти",
            ukrPostDeliveryAddress: `Адреса укрпошти`,
            tasks: "Завдання",
            priceLists: "Прайслісти",
        },
    },
    card: {
        title: "Особиста картка угоди",
        info: {
            title: "Інформація про угоду",
            saveButton: "Зберегти",
        },
    },
    modal: {
        header: "Створити угоду",
        inputs: {
            name: "Назва угоди *",
            nameError: "Назва не коректна",
            additionalSpendings: "Додаткові витрати",
            additionalSpendingsError: "Сума додаткових витрат не коректна",
            client: "Клієнт *",
            responsibleUser: "Відповідальний *",
            comment: "Коментар",
            stage: "Етап",
            source: "Джерело",
            endDate: "Дата завершення",
            responsibleContact: "Відповідальний контакт",
            currency: "Валюта",
            noResponsibleContact: "У клієнта немає додаткових контактів",
            noClientId: "Оберіть клієнта щоб обрати додаткові контакти",
            funnel: "Воронка",
        },
        create: "Створити",
        warningChangingCurrency: "Зміна валюти призведе до збереження поточних змін та видалення з угоди всіх товарів та послуг",
    },
    filter: {
        header: "Фільтр угод",
        inputs: {
            additionalSpendingFrom: "Додаткові витрати від",
            additionalSpendingTo: "Додаткові витрати на",
            amountFrom: "Загальна сума від",
            amountFromError: "Загальна сума не коректна",
            amountTo: "Загальна сума до",
            amountToError: "Загальна сума не коректна",
            currency: "Валюта",
            client: "Клієнт",
            responsibleUser: "Відповідальний",
            comment: "Коментар",
            funnel: "Воронка",
            stage: "Етап",
            source: "Джерело",
            createdAtFrom: "Створено від",
            createdAtTo: "Створено до",
            endDateFrom: "Дата завершення від",
            endDateTo: "Дата завершення до",
            createdAt: "Створено",
            endDate: "Дата завершення",
        },
        placeholders: {
            name: "Введіть назву компанії",
            amountFrom: "Від",
            currency: "Введіть назву валюти",
            amountTo: "До",
            client: "Введіть ім'я клієнта",
            responsibleUser: "Введіть ім'я",
        },
        apply: "Застосувати",
        clear: "Скинути",
    },
    boardView: {
        noStage: "Без етапу",
        copyLink: "Копіювати посилання",
        archive: "Архівувати",
        unArchive: "Розархівувати",
        card: {
            client: "Клієнт",
            deal: "Угода",
        },
    },
};

export default deals;
