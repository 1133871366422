import api from "@/services/api";
import { CreateDeal, CustomField, Deal, DealFilters, DealList, DealSorting, Locale, Pagination, SomeFieldsRequest, UpdateDeal } from "@/services/types";
import { DownloadFileTypeOptions } from "@/utils/import-export";

export const dealsService = {
    async dealList(params: DealFilters | DealSorting | Locale): Promise<DealList> {
        const response = await api.get("/deals", {
            params,
        });
        return response.data;
    },

    async someFields(params: SomeFieldsRequest, clientId?: number): Promise<Partial<Deal>[]> {
        const queryParams: any = { fields: params.fields?.join(",") };
        if (clientId) queryParams["clientId"] = clientId;
        const response = await api.get("/deals/some-fields", {
            params: queryParams,
        });
        return response.data;
    },

    async getDeal(id: string, locale: Locale) {
        const response = await api.get(`/deals/${id}`, { params: { locale } });
        return response.data;
    },

    createDeal(deal: CreateDeal) {
        return api.post("deals", deal);
    },

    updateDeal(id: number, deal: UpdateDeal, locale: Locale): Promise<void> {
        return api.patch(`/deals/${id}`, deal, { params: { locale } });
    },

    bulkUpdate(data: UpdateDeal, locale: Locale): Promise<void> {
        return api.patch(`/deals/bulk`, data, { params: { locale } });
    },

    async dealFieldsList(locale: "en" | "ua") {
        return await api.get<{
            essenceFields: string[];
            customFields: CustomField[];
        }>("/deals/fields", { params: { locale } });
    },

    async dealPriceListFieldsList(locale: "en" | "ua") {
        return await api.get<{ essenceFields: string[] }>("/deal-price-list/fields", { params: { locale } });
    },

    async exportDeals(params: { locale: "en" | "ua" } & { essenceFieldIds: string[]; customFieldIds: number[] } & Omit<DealFilters, keyof Pagination> & DealSorting) {
        const { essenceFieldIds, customFieldIds } = params;
        return await api.get<{ fileUrl: string }>("/deals/export", {
            params: {
                ...params,
                essenceFieldIds: essenceFieldIds.join(","),
                customFieldIds: customFieldIds.join(","),
            },
            headers: {
                tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        });
    },

    async importDeals(data: { locale: "en" | "ua" } & { file: File; uniqueFieldId: string }) {
        const form = new FormData();
        form.append("file", data.file);
        form.append("locale", data.locale);
        form.append("uniqueFieldId", data.uniqueFieldId);

        return await api.postForm<{ errors: ({ [key: string]: any } & { error: any })[]; successCount: number }>("/deals/import", form, {
            headers: {
                tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        });
    },

    async importDealsTemplate(fileType: DownloadFileTypeOptions) {
        return await api.get<{ fileUrl: string }>(`/deals/import/template?fileType=${fileType}`, {
            headers: {
                tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        });
    },
};
