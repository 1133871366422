const priceLists = {
    title: "Price list",
    subtitle: "Track the essentials on the Dashboard",
    table: {
        title: "Services/products",
        emptyMessage: "There is no data",
        emptySearchResult: "No results found",
        panel: {
            searchPlaceholder: "Search",
            archive: "Archive",
            addListItem: "Product/service",
            addCategory: "Add category",
            filter: "Filter",
        },
        columns: {
            id: "ID",
            category: "Category",
            photo: "Photo",
            photoUrl: "Photo",
            article: "Article",
            name: "Name",
            unit: "Unit",
            cost: "Cost",
            price: "Price",
            currency: "Currency",
            url: "Url",
            actions: "Actions",
            newCategoryName: "New category",
            amount: "Amount",
            mainPhoto: "The main photo",
            description: "Description",
            priceListAmount: "Amount",
            createdAt: "Created at",
            barcode: "Barcode",
            barcodeFormat: "Barcode format",
            barcodeComment: "Barcode comment",
            barcodeImg: "Barcode image",
            barcodePdf: "Barcode PDF",
        },
    },
    barcodes: {
        actionMenu: {
            edit: "Edit",
            downloadPdf: "Download PDF",
            delete: "Delete",
        },
        table: {
            title: "Barcodes",
            addBarcode: "Add",
            emptyMessage: "There is no data",
            columns: {
                id: "ID",
                barcode: "Barcodes",
                barcodeImg: "Photo",
                barcodeFormat: "Symbolics",
                barcodeComment: "Comment",
            },
        },

        modal: {
            upsertBarcode: {
                newBarcodeTitle: "New barcode",
                updateBarcodeTitle: "Barcode {barcode}",
                inputs: {
                    modification: "Modification",
                    selectModification: "Choose modification",
                    barcode: "Barcode",
                    barcodePlaceholder: "Type placeholder",
                    format: "Symbolic",
                    comment: "Comment",
                    commentPlaceholder: "Type placeholder",
                },
            },
        },
    },
    modal: {
        header: "Create product / service",
        inputs: {
            category: "Category",
            photo: "Photo",
            photoPlaceholder: "Upload Photo",
            article: "Article",
            articleError: "Article is not valid",
            articlePlaceholder: "Enter the article of the product/service",
            name: "Name *",
            nameError: "Name is not valid",
            namePlaceholder: "Enter the name of the product/service",
            unit: "Unit of Measurement *",
            unitError: "Unit of Measurement is not valid",
            unitPlaceholder: "Enter the unit of measurement",
            cost: "Cost Price *",
            costError: "Cost Price is not valid",
            costPlaceholder: "Enter the cost price of the product/service",
            price: "Price *",
            priceError: "Price is not valid",
            pricePlaceholder: "Enter the price of the product/service",
            currency: "Currency *",
            url: "URL",
            urlError: "Please enter a valid URL (e.g., example.com, www.example.com).",
            urlPlaceholder: "Enter the link to the product/service",
        },
        create: "Create",
        createError: "Error creating the product/service",
    },
    filter: {
        header: "Filter",
        inputs: {
            article: "Article",
            currency: "Currency",
            name: "Name",
            cost: "Cost",
            price: "Price",
        },
        validationMessages: {
            cost: "Cost must be a positive number",
            costRangeError: "Cost from must be lower than to",
            price: "Price must be a positive number",
            priceRangeError: "Price from must be lower than to",
        },
        placeholders: {
            currency: "Enter currency of service or product",
        },
        apply: "Apply",
        clear: "Clear",
    },
    card: {
        title: "Personal card of product/service",
        info: {
            title: "Information about the product/service",
            saveButton: "Save",
        },
    },
    categoryList: {
        select: "Select",
        selected: "Selected",
        open: "Open",
        opened: "Opened",
        items: "items",
    },
    addToDeal: {
        selected: "Selected",
        addAll: "Save all selected",
        modal: "Add or change this deal product/service items",
        selectedTableHint: "Select products/services from top menu for them to appear here",
        errorMessage: "An error occurred when adding products/services",
        missmatchingCurrencyError: "You can only add product/service items with the currency of the deal",
    },
    logs: {
        newPhotos: "Added new photos",
        deletedPhotos: "Deleted photos",
    },
};

export default priceLists;
