import { enUsLocaleName, ukUaLocaleName } from "@/utils/constants";
import Joi from "joi";
import { filterNumber, referenceSchema } from "../common";

const nameErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Назва угоди повинна бути рядком.",
        "string.empty": "Назва угоди не може бути порожня.",
        "string.max": "Назва угоди не може містити більше 100 символів",
        "string.min": "Назва угоди не може містити менше 3 символів",
        "any.required": "Назва угоди є обов'язковою.",
    },
    [enUsLocaleName]: {
        "string.base": "Deal name must be a string.",
        "string.empty": "Deal name cannot be empty.",
        "string.max": "Deal name cannot exceed 100 characters.",
        "string.min": "Deal name cannot be less than 3 characters.",
        "any.required": "Deal name is required.",
    },
};

const clientIdErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": 'Поле "клієнт" є обов\'язковим.',
        "number.positive": 'Поле "клієнт" є обов\'язковим.',
        "number.integer": 'Поле "клієнт" є обов\'язковим.',
        "any.required": 'Поле "клієнт" є обов\'язковим.',
    },
    [enUsLocaleName]: {
        "number.base": 'The "client" field is required.',
        "number.positive": 'The "client" field is required.',
        "number.integer": "The client ID must be an integer.",
        "any.required": 'The "client" field is required.',
    },
};

const responsibleIdErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": 'Поле "відповідальний" є обов\'язковим.',
        "number.positive": 'Поле "відповідальний" є обов\'язковим.',
        "number.integer": 'Поле "відповідальний" є обов\'язковим.',
        "any.required": 'Поле "відповідальний" є обов\'язковим.',
    },
    [enUsLocaleName]: {
        "number.base": 'The "responsible" field is required.',
        "number.positive": 'The "responsible" field is required.',
        "number.integer": 'The "responsible" field is required.',
        "any.required": 'The "responsible" field is required.',
    },
};

const funnelIdErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": 'Поле "воронка" є обов\'язковим.',
        "number.positive": 'Поле "воронка" має бути позитивним числом.',
        "number.integer": "ID воронки має бути цілим числом.",
        "any.required": 'Поле "воронка" є обов\'язковим.',
    },
    [enUsLocaleName]: {
        "number.base": 'The "funnel" field is required.',
        "number.positive": 'The "funnel" field must be a positive number.',
        "number.integer": "The funnel ID must be an integer.",
        "any.required": 'The "funnel" field is required.',
    },
};

const stageIdErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": 'Поле "етап" є обов\'язковим.',
        "number.positive": 'Поле "етап" має бути позитивним числом.',
        "number.integer": "ID етапу має бути цілим числом.",
        "any.required": 'Поле "етап" є обов\'язковим.',
    },
    [enUsLocaleName]: {
        "number.base": 'The "stage" field is required.',
        "number.positive": 'The "stage" field must be a positive number.',
        "number.integer": "The stage ID must be an integer.",
        "any.required": 'The "stage" field is required.',
    },
};

const currencyErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": 'Поле "валюта" є обов\'язковим.',
        "number.positive": 'Поле "валюта" є обов\'язковим.',
        "number.integer": 'Поле "валюта" є обов\'язковим.',
        "string.empty": 'Поле "валюта" є обов\'язковим.',
        "string.base": 'Поле "валюта" є обов\'язковим.',
        "any.required": 'Поле "валюта" є обов\'язковим.',
    },
    [enUsLocaleName]: {
        "number.base": 'The "currency" field is required.',
        "number.positive": 'The "currency" field is required.',
        "number.integer": 'The "currency" field is required.',
        "string.empty": 'The "currency" field is required.',
        "string.base": 'The "currency" field is required.',
        "any.required": 'The "currency" field is required.',
    },
};

const amountErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": 'Поле "сума" повинно бути числом.',
        "number.min": "Сума не може бути меншою за 0.",
    },
    [enUsLocaleName]: {
        "number.base": "Amount must be a number.",
        "number.min": "Amount cannot be less than 0.",
    },
};

const commentErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Коментар повинен бути рядком.",
    },
    [enUsLocaleName]: {
        "string.base": "Comment must be a string.",
    },
};

const responsibleContactIdErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": 'Поле "відповідальний контакт" повинно бути числом.',
        "number.integer": 'Поле "відповідальний контакт" повинно бути цілим числом.',
    },
    [enUsLocaleName]: {
        "number.base": '"responsibleContactId" must be a number.',
        "number.integer": '"responsibleContactId" must be an integer.',
    },
};

const sourceIdErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": 'Поле "джерело" повинно бути числом.',
        "number.integer": 'Поле "джерело" повинно бути цілим числом.',
    },
    [enUsLocaleName]: {
        "number.base": '"sourceId" must be a number.',
        "number.integer": '"sourceId" must be an integer.',
    },
};

const sourceErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": 'Поле "джерело" повинно бути рядком.',
    },
    [enUsLocaleName]: {
        "string.base": '"source" must be a string.',
    },
};

const dateErrMsgs = {
    [ukUaLocaleName]: {
        "date.base": "Невірний формат дати.",
    },
    [enUsLocaleName]: {
        "date.base": "Invalid date format.",
    },
};

const filterNumberErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": "Поле повинно бути числом.",
        "number.min": "Число не може бути меншим за 0.",
    },
    [enUsLocaleName]: {
        "number.base": "Field must be a number.",
        "number.min": "Number cannot be less than 0.",
    },
};

export const createDealSchema = Joi.object({
    name: Joi.string().min(3).max(100).required().messages(nameErrMsgs),
    amount: Joi.number().min(0).allow(0).optional().messages(amountErrMsgs),
    currencyId: Joi.number().integer().positive().required().messages(currencyErrMsgs),
    responsibleId: Joi.number().integer().positive().required().messages(responsibleIdErrMsgs),
    responsibleContactId: Joi.number().integer().allow(-1).optional().messages(responsibleContactIdErrMsgs),
    clientId: Joi.number().integer().positive().required().messages(clientIdErrMsgs),
    comment: Joi.string().allow("").optional().messages(commentErrMsgs),
    stageId: Joi.number().integer().positive().required().messages(stageIdErrMsgs),
    funnelId: Joi.number().integer().positive().required().messages(funnelIdErrMsgs),
    sourceId: Joi.number().integer().allow(-1).optional().messages(sourceIdErrMsgs),
    endDate: Joi.date().optional().messages(dateErrMsgs),
    createdAt: Joi.date().optional().messages(dateErrMsgs),
});

export const updateDealSchema = Joi.object({
    name: Joi.string().min(3).max(100).required().messages(nameErrMsgs),
    amount: Joi.number().min(0).allow(0).optional().messages(amountErrMsgs),
    responsible: Joi.object(referenceSchema).messages(responsibleIdErrMsgs),
    responsibleContact: Joi.object(referenceSchema),
    client: Joi.object(referenceSchema).messages(clientIdErrMsgs),
    comment: Joi.string().allow("").optional().messages(commentErrMsgs),
    stage: Joi.object(referenceSchema).messages(stageIdErrMsgs),
    funnel: Joi.object(referenceSchema).messages(funnelIdErrMsgs),
    source: Joi.object(referenceSchema).messages(sourceErrMsgs),
    endDate: Joi.date().allow(null).optional().messages(dateErrMsgs),
    createdAt: Joi.date().optional().messages(dateErrMsgs),
});

export const dealsFiltersSchema = Joi.object({
    deals_additionalSpendingFrom: filterNumber.messages(filterNumberErrMsgs),
    deals_additionalSpendingTo: filterNumber.messages(filterNumberErrMsgs),
    deals_totalAmountFrom: filterNumber.messages(filterNumberErrMsgs),
    deals_totalAmountTo: filterNumber.messages(filterNumberErrMsgs),
});
