const delivery = {
    table: {
        title: "Information about the delivery",
        emptyMessage: "There are no deliveries, you add them by creating new or adding an existing one",
        emptySearchResult: "No results found",
        panel: {
            createNewTtn: "Create a new TTN",
            addTtn: "Add TTN",
            filter: "Filter",
        },
        searchPlaceholder: "Enter TTN Number",
        actionsMenu: {
            printInvoice: "Print Invoice",
            markingPrint100: "Print Marking 100*100 Type 'Zebra'",
            markingA4: "Marking on A4 Sheet (6 Stickers)",
            delete: "Delete",
        },
        columns: {
            id: "ID",
            created: "Created",
            address: "Address",
            sender: "Sender",
            responsible: "Responsible",
            client: "Lead/Client",
            deal: "Deal",
            ttn: "TTN",
            postPaid: "Postpaid",
            shippingCost: "Shipping cost",
            status: "Status",
            dealStage: "Deal stage",
            paidStorage: "Paid storage",
            estimatedDeliveryDate: "Estimated delivery date",
            dateOfReceiving: "Date of receiving",
        },
    },
    page: {
        title: "Delivery",
        subtitle: "Keep track of the most important things on Dashboard",
    },
    card: {
        title: "Delivery",
        selectCredentialTitle: "Postal account",
        phoneError: "Please enter a phone number with country code, e.g., 380951111111, 0951111111, or +380951111111.",
        selectAdditionalContactTitle: "Recipient additional contact",
        info: {
            title: "Create new delivery",
            saveButton: "Save",
        },
        warning: {
            credentialsId: "Please select account settings to create TTN",
        },
        error: {
            checkValue: "Check the correctness of the entered delivery parameters and values",
            senderCounterpartyMissing: "Sender phone number is not valida. Please enter a valid phone number.",
            recipientCounterpartyMissing: "Recipient phone number is not valid. Please enter a valid phone number.",
            postOfficeGeneralParams: "General parameters are not available for the post office, specify the actual locations for delivery by location in the post office",
            postOfficeDateTime:
                "To create an Express waybill for a post office, " +
                "you need to specify the current date, and take into account that the transfer of the shipment has 6 hours",
            lessThanNowDateTime: "You can't select date of shipment less than now.",
            documentCargoType: "Total weight and Total volume of shipment must be empty for document cargo type",
            validationError: "Field validation did not pass",
        },
        shippingOptions: {
            title: "Shipping Options",
            dateOfShipmentInputTitle: "Date of shipment *",
            counterPartyType: {
                privatePerson: "Private person",
                organization: "Organization",
            },
            TypeOfReceivingMoney: {
                title: "Type of receiving money *",
                withoutReturnShippingRadioBtn: "Without return shipping",
                inTheDepartmentRadioBtn: "In the department",
            },
            DeliveryMethod: {
                title: "Delivery Method *",
                doorsWarehouse: "From door to branch",
                doorsDoors: "From door to door",
                warehouseDoors: "From door to branch",
                warehouseWarehouse: "From branch to branch",
            },
            AnnouncedCost: { title: "Announced cost *", inputTitle: "Enter announced cost ", validationMessage: "" + "Announced cost must be more or equal  1" },
            typeOfCargo: {
                title: "Type of cargo *",
                parcel: "Parcel",
                documents: "Documents",
            },
            postPaid: {
                title: "PostPaid *",
                inputTitle: "Enter cash on delivery",
                validationMessage: "PostPaid must be more or equal  1",
            },
            PayerOfDelivery: {
                title: "Payer of delivery *",
                SenderRadioBtn: "Sender",
                RecipientRadioBtn: "Recipient",
            },
            AdditionalInformation: {
                title: "Additional information",
                inputTitle: "Enter additional information",
            },
            PayerOfCashOnDeliveryFees: {
                title: "Payer of cash on delivery fees *",
                SenderRadioBtn: "Sender",
                RecipientRadioBtn: "Recipient",
            },
            PackageNumber: {
                title: "Package number",
                inputTitle: "Package number",
            },
            DescriptionOfTheCargo: {
                title: "Description of the Cargo *",
                inputTitle: "Describe your cargo",
                validationMessage: "Please enter a description of the shipment that is more than 3 characters long and less 120",
            },
            InternalNumberOfTheDeparture: {
                title: "Internal number of department",
                inputTitle: "Enter the internal reference number",
            },
            FormOfPayment: {
                title: "Form of payment *",
                cash: "Cash",
                nonCash: "Cashless",
            },
            AccompanyingDocuments: {
                title: "Accompanying documents",
                inputTitle: "Attach supporting documents",
            },
        },
        TheSizeOfTheShipment: {
            title: "The size of the shipment",
            addPlace: "Add place",
            viewAvailableDeliveryParams: "View acceptable sending parameters",
            addPlaceToolTip: "A place can be added only with a detailed description of each item",
            GeneralParametersCheckboxTitle: "General parameters",
            generalParametersValidationMessage: "Parameters not valid for departments. Please set valid parameters.",
            shipmentNotInTheBox: "Shipping not in a box",
            totalWeight: {
                title: "Total weight *",
                inputTitle: "Enter the total weight in (kg)",
                validationMessage: "Value must be more than 0.1 and less than 1000",
                validationWarning: "The maximum value for weight",
            },
            NumberOfSeats: {
                title: "Number of seats *",
                inputTitle: "Enter the number of seats",
                validationMessage: "The value must be between 1 and 100",
                documentsCargoTypeValidationMessage: "The value for documents cargo type must be 1",
            },
            TotalVolumeOfShipments: {
                title: "Total volume of shipment *",
                inputTitle: "Enter the volume of the shipment",
                validationMessage: "Value must be more than 0.0004 and less than 2",
                validationWarning: "The maximum value for volume of shipment",
            },
            bulkWeightGeneral: {
                title: "Bulk weight",
                popupText:
                    "The volumetric weight is calculated using the formula (Length × Width × Height, cm) / 4000 and compared to the actual weight. " +
                    "The larger value is used in calculating the cost of transportation.",
            },
            weight: {
                title: "Weight (kg) *",
                inputTitle: "Enter the total weight in (kg)",
                error: "Specify the total weight in (kg)",
            },
            length: {
                title: "Length (cm) *",
                inputTitle: "Enter the length in (cm)",
                error: "Specify the length in (cm)",
            },
            height: {
                title: "Height (cm) *",
                inputTitle: "Enter the height in (cm)",
                error: "Specify the height in (cm)",
            },
            width: {
                title: "Width (cm) *",
                inputTitle: "Enter the width in (cm)",
                error: "Specify the width in (cm)",
            },
            bulkWeight: {
                title: "Volumetric volume (kg) *",
                inputTitle: "Enter the volumetric volume in (kg)",
                error: "Specify the volumetric volume in (kg)",
            },
        },
        SenderData: {
            title: "Sender data",
            city: "City *",
            sender: "Sender type *",
            typeSendingOffice: "Type sending office *",
            sendingOffice: "Sending office *",
            firstName: {
                title: "First name *",
                inputTitle: "Enter the name of the sender",
                validationMessage: "Please enter a name of the sender",
            },
            lastName: {
                title: "Last name *",
                inputTitle: "Enter the last name of the sender",
                validationMessage: "Please enter the last name of the sender",
            },
            patronymic: {
                title: "Patronymic",
                inputTitle: "Enter the patronymic name of the sender",
                validationMessage: "Please enter the patronymic name of the sender",
            },
            phoneOfTheSender: {
                title: "Phone *",
                inputTitle: "Enter the phone of sender",
                validationMessage: "Please enter the phone of the sender",
            },
        },
        RecipientData: {
            title: "Recipient data",
            recipient: "Recipient type *",
            city: "City *",
            typeSendingOffice: "Type sending office *",
            sendingOffice: "Recipient's office *",
            lastName: {
                title: "Last name *",
                inputTitle: "Enter the last name of the recipient",
                validationMessage: "Please enter the last name of the recipient",
            },
            firstName: {
                title: "First name *",
                inputTitle: "Enter the first name of the recipient",
                validationMessage: "Please enter the first name of the recipient",
            },
            patronymic: {
                title: "Patronymic",
                inputTitle: "Enter the patronymic name of the recipient",
            },
            phoneOfTheRecipient: {
                title: "Phone *",
                inputTitle: "Enter the phone of recipient",
                validationMessage: "Please enter the phone of the recipient",
            },
        },
        paymentControl: {
            popupText:
                "To use this function, an additional contract with Nova Poshta is required. " +
                "The description of the service and the conclusion of the contract are clarified with the Nova Poshta support service.",
            title: "Payment control",
            quantity: "Quantity",
        },
    },
    filter: {
        titles: {
            createdFrom: "Created from",
            createdTo: "Created to",
            estimateFrom: "Estimate delivery from",
            estimateTo: "Estimate delivery to",
            receivedFrom: "Received from",
            receivedTo: "Received to",
            branch: "Branch",
            type: "Delivery type",
            stage: "Deal stage",
            status: "Status",
            paidBy: "Paid by",
            sender: "Sender",
            responsible: "Responsible",
            paymentControl: "Payment control",
        },
        placeholders: {
            paymentControl: "Enter the quantity",
        },
        buttons: {
            apply: "Apply",
            clear: "Clear",
        },
    },
    modalErrorAddTtn: {
        createNewDelivery: "An error occurred while creating a TTN",
        addNewExistingTtn: "An error occurred while adding an existing TTN",
        textError: "To use this functionality, you need to connect integration with Nova Poshta or Ukrposhta. You can do this by following the link:",
    },
    modalErrorPrintDelivery: {
        header: "An error occurred while trying to print delivery",
        textError: "Please check if you have access to print this delivery(s) in your Nova Post client",
    },
    modalAddTtn: {
        header: "Add existing TTN",
        add: "Add",
        optionsPostType: {
            novaposhta: "Nova Poshta",
            ukrposhta: "Ukr Poshta",
        },
        phoneNumber: {
            title: "Phone number of the sender",
            placeholder: "Enter the sender's phone number",
        },
        inputs: {
            name: "№ ТТN",
            placeholder: "Enter the consignment note number",
        },
        errors: {
            notCorrectNumber: "Check the correctness of the entered TTN number",
            alreadyExistNumber: "The TTN number you entered already added. Please enter a unique TTN number.",
            emptyNumber: "TTN number can not be empty",
            emptyPhoneNumber: "Sender phone number can not be empty",
            wrongPhoneNumber: "Please enter a valid phone number in the format: 380000000000",
        },
    },
    modalPrintDelivery: {
        header: "Invoice type",
        subtitle: "Nova Post",
        invoiceTypes: {
            expressWaybillRadioBtn: "Express waybill",
            labelingRadioBtn: "Labeling",
            zebraTypeMarkingRadioBtn: "Zebra type marking",
            markingZebraRadioBtn: 'Marking 100*100 type "Zebra"',
        },
        selectedItems: "Selected items: ",
        doNotOpenTabs: "Do not open tabs with invoices when printing",
        print: "Print",
        cancel: "Cancel",
    },
    addressFields: {
        cityPlaceholder: "Type first symbols of your city name in ukrainian language",
        cityTooltip: "Please enter a city in ukrainian language",
    },
};

export default delivery;
