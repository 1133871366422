const notifications = {
    title: "Сповіщення",
    emptyList: "Немає нових сповіщень",
    showAll: "Показати все",
    tabTitles: {
        messages: "Пошта",
        showAll: "Показати все",
    },
    convertedInClient: "Конвертував (-ла) ліда Id {leadId} в клієнта Id {clientId}",
};

export default notifications;
