import { RouteRecordRaw } from "vue-router";
import { RouteIds, RoutesNames } from "./consts";

const main = { name: "main", path: "/" };
const leads = { name: "leads", path: "/leads" };
const lead = (id: string) => ({
    name: "lead",
    param: id,
    path: `/leads/${id}`,
});
const clients = { name: "clients", path: "/clients" };
const client = (id: string) => ({
    name: "client",
    param: id,
    path: `/clients/${id}`,
});
const deals = { name: "deals", path: "/deals" };
const deal = (id: string) => ({
    name: "deal",
    param: id,
    path: `/deals/${id}`,
});
const tasks = { name: "tasks", path: "/tasks" };
const priceLists = { name: "priceLists", path: "/price-list" };
const mails = { name: "mail", path: "/mail" };
const mailBox = (box: string) => ({
    name: "mail",
    translateParam: box,
    path: `/mail/${box}`,
});
const settings = { name: "settings", path: "/settings" };
const userSettings = { name: "userSettings", path: "/settings/users" };
const integrationSettings = {
    name: "integrationSettings",
    path: "/settings/integrations",
};
const postIntegrationSettings = (provider: string) => ({
    name: "",
    translateParam: provider,
    path: `/settings/integrations/${provider}`,
});

export const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: RoutesNames.MAIN,
        component: () => import("@/pages/Dashboard.vue"),
    },
    {
        path: "/leads",
        name: RoutesNames.LEADS,
        component: () => import("@/pages/leads/Leads.vue"),
        meta: { breadcrumbs: [main, leads] },
    },
    {
        path: "/leads/:id",
        name: RoutesNames.LEAD_INFO,
        component: () => import("@/pages/leads/LeadInfo.vue"),
        meta: {
            idName: RouteIds.LEAD,
            breadcrumbs: (route: any) => [main, leads, lead(route.params.id)],
        },
    },
    {
        path: "/clients",
        name: RoutesNames.CLIENTS,
        component: () => import("@/pages/clients/Clients.vue"),
        meta: { breadcrumbs: [main, clients] },
    },
    {
        path: "/clients/:id",
        name: RoutesNames.CLIENT_INFO,
        component: () => import("@/pages/clients/ClientInfo.vue"),
        meta: {
            idName: RouteIds.CLIENT,
            breadcrumbs: (route: any) => [main, clients, client(route.params.id)],
        },
    },
    {
        path: "/deals",
        name: RoutesNames.DEALS,
        component: () => import("@/pages/deals/Deals.vue"),
        meta: { breadcrumbs: [main, deals] },
    },
    {
        path: "/deals/:id",
        name: RoutesNames.DEAL_INFO,
        component: () => import("@/pages/deals/components/deal-info/DealInfo.vue"),
        meta: {
            idName: RouteIds.DEAL,
            breadcrumbs: (route: any) => [main, deals, deal(route.params.id)],
        },
    },
    {
        path: "/tasks",
        name: RoutesNames.TASKS,
        component: () => import("@/pages/tasks/Tasks.vue"),
        meta: { breadcrumbs: [main, tasks] },
    },
    {
        path: "/tasks/:id",
        name: RoutesNames.TASK_INFO,
        component: () => import("@/pages/tasks/TaskInfo.vue"),
        meta: {
            idName: RouteIds.TASK,
            breadcrumbs: (route: any) => [
                main,
                tasks,
                {
                    name: "task",
                    param: route.params.id,
                    path: `/tasks/${route.params.id}`,
                },
            ],
        },
    },
    {
        path: "/calls",
        name: RoutesNames.CALLS,
        component: () => import("@/pages/Calls.vue"),
        meta: {
            breadcrumbs: [
                main,
                {
                    name: "calls",
                    path: "/calls",
                },
            ],
        },
    },
    {
        path: "/price-list",
        name: RoutesNames.PRICE_LIST,
        component: () => import("@/pages/price-list/PriceList.vue"),
        meta: { breadcrumbs: [main, priceLists] },
    },
    {
        path: "/price-list/:id",
        name: RoutesNames.PRICE_LIST_INFO,
        component: () => import("@/pages/price-list/PriceListInfo.vue"),
        meta: {
            idName: RouteIds.PRICE_LIST,
            breadcrumbs: (route: any) => [
                main,
                priceLists,
                {
                    name: "priceList",
                    param: route.params.id,
                    path: `/price-list/${route.params.id}`,
                },
            ],
        },
    },
    {
        path: "/statistic",
        name: RoutesNames.STATISTIC,
        component: () => import("@/pages/Statistic.vue"),
        meta: {
            breadcrumbs: [
                main,
                {
                    name: "statistic",
                    path: "/statistic",
                },
            ],
        },
    },
    {
        path: "/delivery",
        name: RoutesNames.DELIVERY,
        component: () => import("@/pages/delivery/Delivery.vue"),
        meta: {
            breadcrumbs: [
                main,
                {
                    name: "delivery",
                    path: "/delivery",
                },
            ],
        },
    },
    {
        path: "/mail",
        name: RoutesNames.NO_EMAIL_ACCOUNTS,
        component: () => import("@/pages/mails/NoEmailAccounts.vue"),
        meta: {
            breadcrumbs: [main, mails],
        },
    },
    {
        path: "/mail/:box",
        name: RoutesNames.MAIL_BOX,
        component: () => import("@/pages/mails/MailList.vue"),
        meta: {
            breadcrumbs: (route: any) => [main, mailBox(route.params.box)],
        },
    },
    {
        path: "/mail/:box/:id",
        name: RoutesNames.MAIL_MESSAGE,
        component: () => import("@/pages/mails/MailMessage.vue"),
        meta: {
            breadcrumbs: (route: any) => [
                main,
                mailBox(route.params.box),
                {
                    name: "message",
                    param: route.params.id,
                    path: `/mail/${route.params.box}/${route.params.id}`,
                },
            ],
        },
    },
    {
        path: "/settings",
        name: RoutesNames.SETTINGS,
        redirect: "/settings/users",
    },
    {
        path: "/settings/users",
        name: RoutesNames.SETTINGS_USERS,
        component: () => import("@/pages/settings/users/Users.vue"),
        meta: { breadcrumbs: [main, userSettings] },
    },
    {
        path: "/settings/users/:id",
        name: RoutesNames.USER_INFO,
        component: () => import("@/pages/settings/UserInfo.vue"),
        meta: {
            breadcrumbs: (route: any) => [
                main,
                userSettings,
                {
                    name: "userSettings",
                    param: route.params.id,
                    path: `/settings/users/${route.params.id}`,
                },
            ],
        },
    },
    {
        path: "/settings/price-list",
        name: RoutesNames.SETTINGS_PRICE_LIST,
        component: () => import("@/pages/settings/PriceList.vue"),
        meta: {
            breadcrumbs: [
                main,
                {
                    name: "priceListSettings",
                    path: "/settings/price-list",
                },
            ],
        },
    },
    {
        path: "/settings/lead",
        name: RoutesNames.SETTINGS_LEAD,
        component: () => import("@/pages/settings/Lead.vue"),
        meta: {
            breadcrumbs: [
                main,
                {
                    name: "leadSettings",
                    path: "/settings/lead",
                },
            ],
        },
    },
    {
        path: "/settings/client",
        name: RoutesNames.SETTINGS_CLIENT,
        component: () => import("@/pages/settings/Client.vue"),
        meta: {
            breadcrumbs: [
                main,
                {
                    name: "clientSettings",
                    path: "/settings/client",
                },
            ],
        },
    },
    {
        path: "/settings/deal",
        name: RoutesNames.SETTINGS_DEAL,
        component: () => import("@/pages/settings/Deal.vue"),
        meta: {
            breadcrumbs: [
                main,
                {
                    name: "dealSettings",
                    path: "/settings/deal",
                },
            ],
        },
    },
    {
        path: "/settings/task",
        name: RoutesNames.SETTINGS_TASK,
        component: () => import("@/pages/settings/Task.vue"),
        meta: {
            breadcrumbs: [
                main,
                {
                    name: "taskSettings",
                    path: "/settings/task",
                },
            ],
        },
    },
    {
        path: "/settings/roles",
        name: RoutesNames.SETTINGS_ROLES,
        component: () => import("@/pages/settings/roles/Roles.vue"),
        meta: {
            breadcrumbs: [
                main,
                {
                    name: "roleSettings",
                    path: "/settings/roles",
                },
            ],
        },
    },
    {
        path: "/settings/mail",
        name: RoutesNames.SETTINGS_MAIL,
        component: () => import("@/pages/settings/Mail.vue"),
        meta: {
            breadcrumbs: [
                main,
                {
                    name: "mailSettings",
                    path: "/settings/mail",
                },
            ],
        },
    },
    {
        path: "/settings/integrations",
        name: RoutesNames.SETTINGS_INTEGRATIONS,
        component: () => import("@/pages/settings/integrations/Integrations.vue"),
        meta: {
            breadcrumbs: [main, integrationSettings],
        },
    },
    {
        path: "/settings/integrations/:provider",
        name: RoutesNames.POST_INFO,
        component: () => import("@/pages/settings/integrations/components/post/PostInfo.vue"),
        meta: {
            breadcrumbs: (route: any) => [main, integrationSettings, postIntegrationSettings(route.params.provider)],
        },
    },
    {
        path: "/settings/integrations/:provider/account/:id",
        name: RoutesNames.EDIT_PRIVATE_INDIVIDUAL,
        component: () => import("@/pages/settings/integrations/components/post/EditPrivateIndividual.vue"),
        meta: {
            breadcrumbs: (route: any) => [
                main,
                integrationSettings,
                postIntegrationSettings(route.params.provider),
                {
                    name: "account",
                    param: route.params.id,
                    path: `/settings/integrations//${route.params.provider}/account/${route.params.id}`,
                },
            ],
        },
    },
    {
        path: "/settings/notifications",
        name: RoutesNames.SETTINGS_NOTIFICATIONS,
        component: () => import("@/pages/settings/notifications/Notifications.vue"),
        meta: {
            breadcrumbs: [
                main,
                {
                    name: "notificationSettings",
                    path: "/settings/notifications",
                },
            ],
        },
    },
    {
        path: "/settings/personal",
        name: RoutesNames.SETTINGS_PERSONAL,
        component: () => import("@/pages/settings/Personal.vue"),
        meta: {
            breadcrumbs: [
                main,
                {
                    name: "personalSettings",
                    path: "/settings/personal",
                },
            ],
        },
    },
    {
        path: "/notifications",
        name: RoutesNames.NOTIFICATIONS,
        component: () => import("@/pages/notifications/Notifications.vue"),
        meta: {
            breadcrumbs: [
                main,
                {
                    name: "notifications",
                    path: "/notifications",
                },
            ],
        },
    },
    {
        path: "/profile",
        name: RoutesNames.PROFILE,
        component: () => import("@/pages/Profile.vue"),
        meta: {
            breadcrumbs: [
                main,
                {
                    name: "profile",
                    path: "/profile",
                },
            ],
        },
    },
    {
        path: "/create-new-delivery/:type/:id",
        name: RoutesNames.CREATE_NEW_DELIVERY,
        component: () => import("@/pages/delivery/CreateNewDelivery.vue"),
        meta: {
            breadcrumbs: (route: any) => {
                const typeMap = {
                    leadId: [leads, lead(route.params.id)],
                    clientId: [clients, client(route.params.id)],
                    dealId: [deals, deal(route.params.id)],
                };
                const essenceBreadCrumbs = route.params.type ? typeMap[route.params.type as "leadId" | "clientId" | "dealId"] : [];
                return [
                    main,
                    ...essenceBreadCrumbs,
                    {
                        name: "createDelivery",
                        path: `/create-new-delivery/${route.params.type}/${route.params.id}`,
                    },
                ];
            },
        },
    },
    {
        path: "/invitation",
        name: RoutesNames.SIGN_UP_BY_INVITATION,
        component: () => import("@/pages/auth/SignUpByInvitation.vue"),
    },
    {
        path: "/auth",
        name: RoutesNames.AUTH,
        component: () => import("@/pages/auth/Auth.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        component: () => import("@/pages/auth/Auth.vue"),
    },
];
