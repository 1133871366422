import { ref, watch } from "vue";
import { Pagination } from "../../services/types";

const DEFAULT_PER_PAGE = 10;

export function usePagination({ page = 1, limit = DEFAULT_PER_PAGE, totalCount = 0, pagesCount }: Partial<Pagination>) {
    const pageCountFn = (total: number, limit: number) => Math.ceil(total / (limit || DEFAULT_PER_PAGE));
    const pagination = ref<Pagination>({ page, limit, pagesCount: pagesCount ?? pageCountFn(totalCount, limit), totalCount });

    watch([pagination.value.totalCount, pagination.value.limit, pagination.value.pagesCount], () => {
        pagination.value.pagesCount = pagesCount ?? pageCountFn(pagination.value.totalCount, pagination.value.limit);
        if (pagination.value.page ?? 1 > pagination.value.pagesCount) {
            pagination.value.page = pagination.value.pagesCount || 1;
        }
    });

    return pagination;
}
