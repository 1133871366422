export const getMondayOfWeek = (date: Date) => {
    // getDay returns: 0 - Sunday, 1 - Monday ... 6 - Saturday
    while (date.getDay() != 1) {
        date.setDate(date.getDate() - 1);
    }
    return date;
};

export const getAllWeeksOfMonth = (date: Date): Date[][] => {
    const startDate = new Date(date.valueOf());
    startDate.setDate(1);
    const mondayDate = getMondayOfWeek(startDate);
    const lastDay = new Date(mondayDate.valueOf());
    lastDay.setDate(mondayDate.getDate() + 35);
    const rows = lastDay.getMonth() !== date.getMonth() ? 5 : 6;
    const month: Date[][] = [];
    for (let i = 0; i < rows; i++) {
        const week: Date[] = [];
        for (let j = 0; j < 7; j++) {
            week.push(new Date(mondayDate.getTime()));
            mondayDate.setDate(mondayDate.getDate() + 1);
        }
        month.push(week);
    }

    return month;
};

export const dayInRange = (day: Date, range?: [Date, Date]): boolean => {
    if (!range) return false;

    return day >= range[0] && day <= range[1];
};

export const dayEqualsEndsOfRange = (day: Date, range?: [Date, Date]): boolean => {
    if (!range) return false;
    return dayEqualsDay(day, range[0]) || dayEqualsDay(day, range[1]);
};

export const dayEqualsDay = (day1: Date, day2: Date): boolean => {
    return (
        day1.getDate() == day2.getDate() &&
        day1.getMonth() == day2.getMonth() &&
        day1.getFullYear() == day2.getFullYear()
    );
};

export const formatDate = (d: Date | undefined, includeTime: boolean = true): string => {
    if (!d) return "";

    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();

    const hours = String(d.getHours()).padStart(2, "0");
    const minutes = String(d.getMinutes()).padStart(2, "0");
    const seconds = String(d.getSeconds()).padStart(2, "0");

    const time = `\n${hours}:${minutes}:${seconds}`;
    return `${day}.${month}.${year}${includeTime ? time : ""}`;
};

export const dateFromString = (string: string) => {
    const dateParts = string.split(".");
    const date = new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0]));

    return date && !Number.isNaN(date.getMonth()) ? date : undefined;
};
