import { enUsLocaleName, ukUaLocaleName } from "@/utils/constants";
import Joi from "joi";
import { filterNumber } from "../common";

const nameErrMsgs = {
    [ukUaLocaleName]: {
        "string.empty": "Назва прайсліста не може бути порожня.",
        "string.max": "Назва прайсліста не може містити більше 100 символів",
        "string.min": "Назва прайсліста не може містити менше 3 символів",
    },
    [enUsLocaleName]: {
        "string.empty": "The price list name cannot be empty.",
        "string.max": "The price list name cannot exceed 100 characters.",
        "string.min": "The price list name must be at least 3 characters long.",
    },
};

const unitsErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": 'Поле "Одиниця виміру " є обов\'язковим.',
        "number.positive": 'Поле "Одиниця виміру " є обов\'язковим.',
        "number.integer": 'Поле "Одиниця виміру " є обов\'язковим.',
        "string.empty": 'Поле "Одиниця виміру " є обов\'язковим.',
        "any.required": 'Поле "Одиниця виміру " є обов\'язковим.',
    },
    [enUsLocaleName]: {
        "number.base": 'The field  "unit of measurments" is required.',
        "number.positive": 'The field "unit of measurments" is required.',
        "number.integer": 'The field "unit of measurments" is required.',
        "string.empty": 'The field "unit of measurments" is required.',
        "any.required": 'The field "unit of measurments" is required.',
    },
};

const costAndPriceErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": "Поле повинно бути числом.",
        "number.positive": "Поле повинно бути більшим за 0.",
        "number.integer": "Поле повинно бути цілим числом.",
        "any.required": "Поле є обов'язковим.",
    },
    [enUsLocaleName]: {
        "number.base": "The field must be a number.",
        "number.positive": "The field must be greater than 0.",
        "number.integer": "The field must be an integer.",
        "any.required": "The field is required.",
    },
};

const urlErrMsgs = {
    [ukUaLocaleName]: {
        "string.pattern.base": "Будь ласка, введіть правильний формат URL, наприклад, починаючи з https://, http:// або www.",
    },
    [enUsLocaleName]: {
        "string.pattern.base": "Please enter a correct URL format, such as starting with https://, http://, or www.",
    },
};

const currencyErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": 'Поле "валюта" є обовʼязковим.',
        "number.integer": 'Поле "валюта" є обовʼязковим.',
        "number.positive": 'Поле "валюта" є обовʼязковим.',
        "any.required": 'Поле "валюта" є обовʼязковим.',
    },
    [enUsLocaleName]: {
        "number.base": 'The "currency" field is required.',
        "number.integer": 'The "currency" field is required.',
        "number.positive": 'The "currency" field is required.',
        "any.required": 'The "currency" field is required.',
    },
};

const commonFields = Joi.object({
    name: Joi.string().min(3).max(100).required().messages(nameErrMsgs),
    categoryId: Joi.number().min(0).optional().allow(0),
    file: Joi.any().optional(),
    article: Joi.string().optional().allow(""),
    unit: Joi.string().min(1).max(20).required().messages(unitsErrMsgs),
    cost: Joi.number().min(0).precision(15).required().messages(costAndPriceErrMsgs),
    price: Joi.number().min(0).precision(15).required().messages(costAndPriceErrMsgs),
    createdAt: Joi.date().optional(),
});

const urlSchema = Joi.string()
    .pattern(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/)
    .allow("")
    .optional()
    .messages(urlErrMsgs);

export const createPriceListSchema = commonFields.append({
    url: urlSchema,
    currencyId: Joi.number().integer().positive().required().messages(currencyErrMsgs),
});

export const updatePriceListSchema = commonFields.append({
    url: urlSchema.allow(null),
    currency: Joi.object().required().messages(currencyErrMsgs),
});

export const priceListFiltersSchema = Joi.object({
    priceList_costFrom: filterNumber,
    priceList_costTo: filterNumber,
    priceList_priceFrom: filterNumber,
    priceList_priceTo: filterNumber,
});

export * from "./barcodes";
