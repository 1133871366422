export enum DeliveryProviders {
    UKR_POST = "ukr-post",
    NOVA_POST = "nova-post",
}

export enum AdditionalInfoTypes {
    WITHOUT_AUTO_GENERATION = "WITHOUT_AUTO_GENERATION",
    WITH_PRODUCT_NAME = "WITH_PRODUCT_NAME",
    WITH_ARTICLE_AND_AMOUNT = "WITH_ARTICLE_AND_AMOUNT",
}

export enum TypeOfReceivingMoney {
    WITHOUT_SHIPPING = "WITHOUT_SHIPPING",
    IN_THE_DEPARTMENT = "IN_THE_DEPARTMENT",
}

export enum DeliveryPayers {
    SENDER = "SENDER",
    RECIPIENT = "RECIPIENT",
}

export enum CargoTypes {
    PARCEL = "PARCEL",
    DOCUMENTS = "DOCUMENTS",
}

export enum PaymentMethods {
    CASH = "CASH",
}

export enum ClientTypes {
    INDIVIDUAL = "INDIVIDUAL",
    CORPORATE = "CORPORATE",
    THIRD_PERSON = "THIRD_PERSON",
}

export enum DeliveryTypes {
    W2W = "W2W",
    W2D = "W2D",
    D2W = "D2W",
    D2D = "D2D",
}

export enum AdditionalInfoInternalNumberTypes {
    EMPTY = "EMPTY",
    WITH_DEAL_ID = "WITH_DEAL_ID",
    WITH_DEAL_NAME = "WITH_DEAL_NAME",
    FROM_PRICE_LIST = "FROM_PRICE_LIST",
}

export enum ParcelsAmount {
    FROM_DEAL_PRICE_LIST_AMOUNT = "FROM_DEAL_PRICE_LIST_AMOUNT",
    FROM_PRICE_LIST_AMOUNT = "FROM_PRICE_LIST_AMOUNT",
}

export enum DeliverySizeTypes {
    FROM_GENERAL_SETTINGS = "FROM_GENERAL_SETTINGS",
    ENABLE_SELECT = "ENABLE_SELECT",
    FILL_PAYMENT_CONTROL_IF_NO_BACK_DELIVERY = "FILL_PAYMENT_CONTROL_IF_NO_BACK_DELIVERY",
}

export const DeliveryProviderMappings = {
    [DeliveryProviders.NOVA_POST]: {
        clientType: {
            [ClientTypes.INDIVIDUAL]: "PrivatePerson",
            [ClientTypes.CORPORATE]: "Organization",
            [ClientTypes.THIRD_PERSON]: "ThirdPerson",
        },
        deliveryType: {
            [DeliveryTypes.D2W]: "DoorsWarehouse",
            [DeliveryTypes.W2W]: "WarehouseWarehouse",
            [DeliveryTypes.D2D]: "DoorsDoors",
            [DeliveryTypes.W2D]: "WarehouseDoors",
        },
        cargoTypes: {
            [CargoTypes.PARCEL]: "Parcel",
            [CargoTypes.DOCUMENTS]: "Documents",
        },
        deliveryPayer: {
            [DeliveryPayers.SENDER]: "Sender",
            [DeliveryPayers.RECIPIENT]: "Recipient",
        },
        deliveryTaxPayer: {
            [DeliveryPayers.SENDER]: "Sender",
            [DeliveryPayers.RECIPIENT]: "Recipient",
        },
        paymentType: {
            [PaymentMethods.CASH]: "Cash",
        },
    },
    [DeliveryProviders.UKR_POST]: {
        clientType: {
            [ClientTypes.INDIVIDUAL]: "PrivatePerson",
            [ClientTypes.CORPORATE]: "Organization",
            [ClientTypes.THIRD_PERSON]: "ThirdPerson", // todo
        },
        deliveryType: {
            [DeliveryTypes.D2W]: DeliveryTypes.D2W,
            [DeliveryTypes.W2W]: DeliveryTypes.W2W,
            [DeliveryTypes.D2D]: DeliveryTypes.D2D,
            [DeliveryTypes.W2D]: DeliveryTypes.W2D,
        },
        cargoTypes: {
            [CargoTypes.PARCEL]: "Parcel",
            [CargoTypes.DOCUMENTS]: "Documents",
        },
        deliveryPayer: {
            [DeliveryPayers.SENDER]: "Sender",
            [DeliveryPayers.RECIPIENT]: "Recipient",
        },
        deliveryTaxPayer: {
            [DeliveryPayers.SENDER]: "senderPayerOfCashOnDelivery",
            [DeliveryPayers.RECIPIENT]: "recipientPayerOfCashOnDelivery",
        },
        paymentType: {
            [PaymentMethods.CASH]: "Cash",
        },
    },
};

type UkrPostAddress = {
    postcode: string;
    city: string;
    region?: string;
    district?: string;
    street?: string;
    houseNumber?: string;
    apartmentNumber?: string;
};

type UkrPostClientInfo = {
    firstName: string;
    lastName: string;
    middleName: string;
    phoneNumber: string;
    type: ClientTypes;
    email?: string | null;
};

export type UkrPostDelivery = {
    clientId: number | null;
    leadId: number | null;
    dealId: number | null;
    sender: {
        address: UkrPostAddress;
        clientInfo: UkrPostClientInfo;
    };
    recipient: {
        address: UkrPostAddress;
        clientInfo: UkrPostClientInfo;
    };
    deliveryInfo: {
        deliveryType: DeliveryTypes;
        weight: number;
        length: number;
        height: number;
        postPay: number;
        recommended: boolean;
        sms: boolean;
        paidByRecipient: boolean;
        parcels: [
            {
                name: string;
                weight: number;
                length: number;
                declaredPrice: number;
            }
        ];
    };
    credentialId: number;
    provider: DeliveryProviders;
};
