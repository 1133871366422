const delivery = {
    table: {
        title: "Інформація про доставку",
        emptyMessage: "Доставок немає. Ви можете створити нову або додати існуючу.",
        emptySearchResult: "Даних не знайдено за вашим запитом",
        panel: {
            createNewTtn: "Створити нову ТТН",
            addTtn: "Додати ТТН",
            filter: "Фільтр",
        },
        searchPlaceholder: "Введіть номер ТТН",
        actionsMenu: {
            printInvoice: "Друк накладної",
            markingPrint100: "Друк маркування 100*100 типу 'Зебра'",
            markingA4: "Маркування на листі А4 (6 наклейок)",
            delete: "Видалити",
        },
        columns: {
            id: "ID",
            created: "Створено",
            address: "Адреса",
            sender: "Відправник",
            responsible: "Відповідальний",
            client: "Лід/Клієнт",
            deal: "Угода",
            ttn: "ТТН",
            postPaid: "Післяплата",
            shippingCost: "Вартість доставки",
            status: "Статус",
            dealStage: "Етап угоди",
            paidStorage: "Платне зберігання",
            estimatedDeliveryDate: "Планова дата доставки",
            dateOfReceiving: "Дата отримання",
        },
    },
    page: {
        title: "Доставка",
        subtitle: "Відслідковуй найголовніше на Дешборді",
    },
    card: {
        title: "Доставка",
        selectCredentialTitle: "Поштовий акаунт",
        phoneError: "Будь ласка, введіть номер телефону з кодом країни, наприклад, 380951111111, 0951111111 або +380951111111.",
        selectAdditionalContactTitle: "Додатковий контакт отримувача",
        info: {
            title: "Створити нову ТТН",
            saveButton: "Зберегти",
        },
        warning: {
            credentialsId: "Будь ласка виберіть налаштування акаунта для створення ТТН",
        },
        error: {
            checkValue: "Перевірте правильність введених параметрів та значень доставки",
            senderCounterpartyMissing: "Номер телефону відправника не є дійсним. Будь ласка, введіть дійсний номер телефону.",
            recipientCounterpartyMissing: "Номер телефону одержувача не є дійсним. Будь ласка, введіть дійсний номер телефону.",
            postOfficeGeneralParams: "Для поштомату не доступні загальні параметри вкажіть фактичні місця для доставки по місцям в поштоматі",
            lessThanNowDateTime: "Ви не можете вибрати дату відвантаження менше, ніж зараз",
            postOfficeDateTime: "Для створення Експрес накладної на поштомат потрібно вказати поточну дату," + " та врахувати, що на передачу відправлення є 6 годин",
            documentCargoType: "Загальна вага та загальний об'єм відправлення повинні бути порожніми для типу документального вантажу",
            validationError: "Валідація поля не пройдена",
        },
        shippingOptions: {
            title: "Параметри Відправлення",
            dateOfShipmentInputTitle: "Дата відправки *",
            counterPartyType: {
                privatePerson: "Приватна особа",
                organization: "Організація",
            },
            TypeOfReceivingMoney: {
                title: "Тип отримання грошей *",
                withoutReturnShippingRadioBtn: "Без зворотної доставки",
                inTheDepartmentRadioBtn: "У відділенні",
            },
            DeliveryMethod: {
                title: "Метод доставки *",
                doorsWarehouse: "З дверей до відділення",
                warehouseDoors: "З відділення до дверей",
                doorsDoors: "З дверей до дверей",
                warehouseWarehouse: "З відділення до відділення",
            },
            AnnouncedCost: {
                title: "Оголошена вартість *",
                inputTitle: "Впишіть оголошену вартість",
                validationMessage: "" + "Оціночна вартість повинна бути більше або дорівнювати 1",
            },
            typeOfCargo: {
                title: "Тип вантажу *",
                parcel: "Посилка",
                documents: "Документи",
            },
            postPaid: {
                title: "Післяплата *",
                inputTitle: "Впишіть післяплату",
                validationMessage: "" + "Післяплата повинна бути більше або дорівнювати 1",
            },
            PayerOfDelivery: {
                title: "Платник доставки *",
                SenderRadioBtn: "Відправник",
                RecipientRadioBtn: "Отримувач",
            },
            AdditionalInformation: {
                title: "Додаткова інформація",
                inputTitle: "Введіть додаткову інформацію",
            },
            PayerOfCashOnDeliveryFees: {
                title: "Платник комісії за післяплату *",
                SenderRadioBtn: "Відправник",
                RecipientRadioBtn: "Отримувач",
            },
            PackageNumber: {
                title: "Номер пакування",
                inputTitle: "Номер пакування",
            },
            DescriptionOfTheCargo: {
                title: "Опис вантажу *",
                inputTitle: "Опишіть ваш вантаж",
                validationMessage: "Будь ласка, введіть опис вантажу який становить більше 3 символів і менше 120",
            },
            InternalNumberOfTheDeparture: {
                title: "Внутрішній номер відправлення",
                inputTitle: "Введіть внутрішній номер відправлення",
            },
            FormOfPayment: {
                title: "Форма оплати *",
                cash: "Готівковий",
                nonCash: "Безготівковий",
            },
            AccompanyingDocuments: {
                title: "Супроводжуючі документи",
                inputTitle: "Прикріпіть супроводжуючі документи",
            },
        },
        TheSizeOfTheShipment: {
            title: "Розмір відправлення",
            addPlace: "Додати місце",
            viewAvailableDeliveryParams: "Переглянути прийнятні параметри відправки",
            addPlaceToolTip: "Місце можна додати лише при детальному описі кожної позиції",
            GeneralParametersCheckboxTitle: "Загальні параметри",
            generalParametersValidationMessage: "Параметри не дійсні для відділів. Будь ласка, встановіть дійсні параметри.",
            shipmentNotInTheBox: "Відправлення не в коробці",
            totalWeight: {
                title: "Загальна вага (кг) *",
                inputTitle: "Впишіть загальну вагу в (кг)",
                validationMessage: "Значення має бути більше 0,1 та менше ніж 1000",
                validationWarning: "Максимальне значення для ваги",
            },
            NumberOfSeats: {
                title: "Кількість місць *",
                inputTitle: "Введіть кількість місць",
                validationMessage: "Значення має бути від 1 до 100",
                documentsCargoTypeValidationMessage: "Значення для типу вантажу документи має бути 1",
            },
            bulkWeightGeneral: {
                title: "Об’ємна вага",
                popupText:
                    "Об’ємна вага розраховується за формулою (Довжина × Ширина × Висота, см) / 4000 та порівнюється з фактичною вагою. " +
                    "Більший показник використовується в розрахунках вартості перевезення.",
            },
            TotalVolumeOfShipments: {
                title: "Загальний об’єм відправлення *",
                inputTitle: "Введіть об’єм відправлення",
                validationMessage: "Значення має бути більше 0,0004 та менше 2",
                validationWarning: "Максимальне значення для об'єму",
            },
            weight: {
                title: "Вага (кг) *",
                inputTitle: "Впишіть загальну вагу в (кг)",
                error: "Вкажіть загальну вагу в (кг)",
            },
            length: {
                title: "Довжина (см) *",
                inputTitle: "Впишіть довжину в (см)",
                error: "Вкажіть довжину в (см)",
            },
            height: {
                title: "Висота (см) *",
                inputTitle: "Впишіть висоту в (см)",
                error: "Вкажіть висоту в (см)",
            },
            width: {
                title: "Ширина(см) *",
                inputTitle: "Впишіть ширину в (см)",
                error: "Вкажіть ширину в (см)",
            },
            bulkWeight: {
                title: "Обʼємна подача (кг) *",
                inputTitle: "Впишіть обʼємну подача в (кг)",
                error: "Вкажіть обʼємну подача в (кг)",
            },
        },
        SenderData: {
            title: "Дані відправника *",
            city: "Місто *",
            typeSendingOffice: "Тип відділення *",
            sender: "Тип відправника *",
            sendingOffice: "Відділення відправника *",
            lastName: {
                title: "Прізвище *",
                inputTitle: "Введіть прізвище та ім'я відправника",
                validationMessage: "Будь ласка, введіть прізвище відправника",
            },
            firstName: {
                title: "Ім’я *",
                inputTitle: "Введіть ім'я відправника",
                validationMessage: "Будь ласка, введіть ім'я відправника",
            },
            patronymic: {
                title: "По батькові",
                inputTitle: "Введіть ім'я по батькові відправника",
                validationMessage: "Будь ласка, введіть ім'я по батькові відправника",
            },
            phoneOfTheSender: {
                title: "Телефон *",
                inputTitle: "Введіть телефон відправника",
                validationMessage: "Будь ласка, введіть телефон відправника",
            },
        },
        RecipientData: {
            title: "Дані отримувача",
            recipient: "Тип отримувача *",
            city: "Місто *",
            typeSendingOffice: "Тип відділення *",
            sendingOffice: "Відділення отримувача *",
            lastName: {
                title: "Прізвище *",
                inputTitle: "Введіть прізвище одержувача",
                validationMessage: "Будь ласка, введіть прізвище",
            },
            firstName: {
                title: "Ім’я *",
                inputTitle: "Введіть ім'я одержувача",
                validationMessage: "Будь ласка, введіть імʼя одержувача",
            },
            patronymic: {
                title: "По батькові",
                inputTitle: "Введіть ім'я по батькові одержувача",
            },
            phoneOfTheRecipient: {
                title: "Телефон *",
                inputTitle: "Введіть телефон одержувача",
                validationMessage: "Будь ласка, введіть номер телефону одержувача",
            },
        },
        paymentControl: {
            popupText:
                "Для використання цієї функції потрібен додатковй договір з компанією Нова Пошта." +
                " Опис послуги та укладення договору уточняється зі службою підтримки Нова Пошта.",
            title: "Контроль оплати",
            quantity: "Кількість",
        },
    },
    filter: {
        titles: {
            createdFrom: "Створено від",
            createdTo: "Створено до",
            estimateFrom: "Планова дата доставки від",
            estimateTo: "Планова дата доставки до",
            receivedFrom: "Отримано від",
            receivedTo: "Отримано до",
            branch: "Філія",
            type: "Тип доставки",
            stage: "Етап угоди",
            status: "Статус",
            paidBy: "Платник",
            sender: "Відправник",
            responsible: "Відповідальний",
            paymentControl: "Контроль оплати",
        },
        placeholders: {
            paymentControl: "Введіть кількість",
        },
        buttons: {
            apply: "Застосувати",
            clear: "Скинути",
        },
    },
    modalErrorAddTtn: {
        createNewDelivery: "Виникла помилка при створенні ТТН",
        addNewExistingTtn: "Виникла помилка при додаванні існуючої ТТН",
        textError: "Для використання даного функціоналу, вам потрібно підʼєднати інтеграцію з Новою Поштою або Укрпоштою. " + "Зробити це ви можете за посиланням: ",
    },
    modalErrorPrintDelivery: {
        header: "Виникла помилка при спробі надрукувати доставку",
        textError: "Будь ласка, перевірте, чи є у вас доступ до друку цього доставлення(нь) у вашому клієнті Нової Пошти",
    },
    modalAddTtn: {
        header: "Додати існуючу ТТН",
        add: "Додати",
        optionsPostType: {
            novaposhta: "Нова Пошта",
            ukrposhta: "Укр Пошта",
        },
        phoneNumber: {
            title: "Номер телефону відправника",
            placeholder: "Введіть номер телефону відправника",
        },
        inputs: {
            name: "№ ТТН",
            placeholder: "Введіть номер товарно-транспортної накладної (ТТН)",
        },
        errors: {
            notCorrectNumber: "Перевірте правильність введеного номеру ТТН",
            alreadyExistNumber: "Введений номер ТТН вже додано. Будь ласка, введіть унікальний номер ТТН.",
            emptyNumber: "Номер ТТН не може бути порожнім",
            emptyPhoneNumber: "Номер телефону відправника не може бути порожнім",
            wrongPhoneNumber: "Будь ласка введіть правильний номер телефону в форматі: 380000000000\n",
        },
    },
    modalPrintDelivery: {
        header: "Тип накладних",
        subtitle: "Нова Пошта",
        invoiceTypes: {
            expressWaybillRadioBtn: "Експрес накладна",
            labelingRadioBtn: "Маркування",
            zebraTypeMarkingRadioBtn: "Маркування типу 'Зебра'",
            markingZebraRadioBtn: "Маркування 100*100 типу 'Зебра'",
        },
        doNotOpenTabs: "Не відкривати вкладки з накладними при друку",
        selectedItems: "Обрано елементів: ",
        print: "Друк",
        cancel: "Скасувати",
    },
    addressFields: {
        cityPlaceholder: "Введіть перші символи назви міста українською мовою",
        cityTooltip: "Будь ласка, введіть місто українською мовою",
    },
};

export default delivery;
