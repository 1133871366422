import { LogMethod } from "@/services/enums";

const logs = {
    title: "Журнал активності",
    noLogs: "Логів не знайдено",
    noEmailLogs: "Логи не знайдено, підключіть, будь ласка, поштовий аккаунт",
    essences: {
        lead: "ліда",
        client: "клієнта",
        deal: "угоду",
        task: "задачу",
        priceList: "прайсліст",
        leadInCLient: "ліда {leadId} в клієнта {clientId}",
    },
    methods: {
        created: "створив(-ла)",
        updated: "оновив(-ла)",
        archived: "архівував(-ла)",
        dearchived: "розархівував(-ла)",
        converted: "конвертував (-ла)",
        ["added-nova-post-delivery-by-ttn"]: "додав(-ла) доставку нової пошти для",
        ["created-nova-post-delivery"]: "створив(-ла) доставку нової пошти для",
        ["removed-delivery"]: "видалив(-ла) доставку для",
        ["created-additional-contact"]: "створив(-ла) додатковий контакт для",
        ["updated-additional-contact"]: "оновив(-ла) додатковий контакт для",
        ["deleted-additional-contact"]: "видалив(-ла) додатковий контакт для",
        ["created-note"]: "створив(-ла) нотатку для",
        ["updated-note"]: "оновив(-ла) нотатку для",
        ["deleted-note"]: "видалив(-ла) нотатку для",
        ["created-barcode"]: "створив(-ла) штрихкод для",
        ["updated-barcode"]: "оновив(-ла) штрихкод для",
        ["deleted-barcode"]: "видалив(-ла) штрихкод для",
        ["task-assigned-to-customer"]: "призначив(-ла) завдання для",
        ["task-reassigned-to-customer"]: "перепризначив(-ла) завдання для",
        ["task-assigned-to-deal"]: "призначив(-ла) завдання для",
        ["task-reassigned-to-deal"]: "перепризначив(-ла) завдання для",
        ["deal-assigned-to-customer"]: "призначив(-ла) угоду для",
        ["deal-reassigned-to-customer"]: "перепризначив(-ла) угоду для",
        ["deal-rewrite-to-price-lists"]: "змінено список прайсліст для",
    },
    ttn: "ТТН",
};

export default logs;
