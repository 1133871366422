import api from "@/services/api";
import { AxiosProgressEvent } from "axios";
import {
    Barcode,
    BarcodeChangableFields,
    CustomField,
    Locale,
    Pagination,
    PriceList,
    PriceListFilters,
    PriceListMultiAction,
    PriceListResponse,
    PriceListSorting,
} from "@/services/types";
import { DownloadFileTypeOptions } from "@/utils/import-export";

export const priceListService = {
    async getPriceList(params: PriceListFilters | PriceListSorting | Locale) {
        return api.get<PriceListResponse>("/price-list", { params });
    },
    createPriceList(payload: FormData, onUploadProgress: (progressEvent: AxiosProgressEvent) => void): Promise<void> {
        return api.post("/price-list", payload, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
            timeout: 1000 * 60 * 20,
        });
    },
    async getPriceListById(id: number): Promise<void> {
        const response = await api.get(`/price-list/${id}`);
        return response.data;
    },
    updatePriceList(id: number, payload: FormData, onUploadProgress: (progressEvent: AxiosProgressEvent) => void): Promise<void> {
        return api.patch(`/price-list/${id}`, payload, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
            timeout: 1000 * 60 * 20,
        });
    },
    bulkUpdate(data: PriceListMultiAction): Promise<void> {
        return api.patch(`/price-list/bulk`, data);
    },
    async rewriteDealPriceList(dealId: number, priceList: (PriceList & { amount: number })[]) {
        const response = await api.post(`/deal-price-list/rewrite`, {
            deal: dealId,
            newDealPriceLists: priceList.map((item) => ({ priceList: item.id, priceListAmount: item.amount, price: item.price, currencyId: item.currency.id })),
        });
        return response.data;
    },

    async createBarcode(priceListId: number, barcode: BarcodeChangableFields): Promise<Barcode> {
        const response = await api.post(`/price-list/barcode`, {
            priceListId,
            barcode,
        });

        return response.data;
    },
    async updateBarcode(priceListId: number, barcode: BarcodeChangableFields): Promise<Barcode> {
        const response = await api.patch(`/price-list/barcode`, {
            priceListId,
            barcode: {
                barcode: barcode.barcode,
                barcodeFormat: barcode.barcodeFormat,
                barcodeComment: barcode.barcodeComment,
            },
        });

        return response.data;
    },
    async deleteBarcode(priceListId: number): Promise<any> {
        const response = await api.delete(`/price-list/barcode/${priceListId}`);

        return response.data;
    },

    async priceListFieldsList(locale: "en" | "ua") {
        return await api.get<{
            essenceFields: string[];
            customFields: CustomField[];
        }>("/price-list/fields", { params: { locale } });
    },

    async exportPriceLists(
        params: { locale: "en" | "ua"; isArchived: boolean } & { essenceFieldIds: string[]; customFieldIds: number[] } & Omit<PriceListFilters, keyof Pagination> &
            PriceListSorting
    ) {
        const { essenceFieldIds, customFieldIds } = params;
        return await api.get<{ fileUrl: string }>("/price-list/export", {
            params: {
                ...params,
                essenceFieldIds: essenceFieldIds.join(","),
                customFieldIds: customFieldIds.join(","),
            },
            headers: {
                tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        });
    },

    async importPriceLists(data: { locale: "en" | "ua" } & { file: File; uniqueFieldId: string }) {
        const form = new FormData();
        form.append("file", data.file);
        form.append("locale", data.locale);
        form.append("uniqueFieldId", data.uniqueFieldId);

        return await api.postForm<{ errors: ({ [key: string]: any } & { error: any })[]; successCount: number }>("/price-list/import", form, {
            headers: {
                tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        });
    },

    async importPriceListsTemplate(fileType: DownloadFileTypeOptions) {
        return await api.get<{ fileUrl: string }>(`/price-list/import/template?fileType=${fileType}`, {
            headers: {
                tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        });
    },
};
