import { ActionContext } from "vuex";

import { statusesService } from "@/services";
import { Status } from "@/services/types";
import { SourceType } from "@/services/enums";
import { useMutation, useQuery } from "@tanstack/vue-query";

export interface StatusState {
    statuses: Status[];
}

const StatusesModule = {
    namespaced: true,
    state: {
        statuses: [] as Status[],
    },
    mutations: {
        setStatuses: (state: StatusState, payload: Status[]): void => {
            state.statuses = payload;
        },
    },
    actions: {
        statusList: async ({ commit }: ActionContext<unknown, unknown>, sourceType: SourceType): Promise<void> => {
            try {
                const statuses: Status[] = (await statusesService.statusList(sourceType)).data;
                commit("setStatuses", statuses);
            } catch (e) {
                console.error(e);
            }
        },
    },
};

export const useStatuses = (sourceType: SourceType) =>
    useQuery({
        queryKey: ["statuses", sourceType],
        queryFn: () => statusesService.statusList(sourceType).then((res) => res.data),
        initialData: [],
    });

export const useRemoveStatus = (sourceType: SourceType) =>
    useMutation({
        mutationFn: (statusId: number) => statusesService.removeStatus(statusId, sourceType),
    });

export const useCreateStatus = (sourceType: SourceType) =>
    useMutation({
        mutationFn: (status: Pick<Status, "ua" | "en">) => statusesService.createStatus(status, sourceType),
    });

export const useUpdateStatus = (sourceType: SourceType) =>
    useMutation({
        mutationFn: (data: { id: number; status: Partial<Omit<Status, "id">> }) => statusesService.updateStatus(data.id, data.status, sourceType),
    });

export const useReorderStatuses = (sourceType: SourceType) =>
    useMutation({
        mutationFn: (data: { id: number; newIndex: number }) => statusesService.reorderStatuses(data, sourceType),
    });

export default StatusesModule;
