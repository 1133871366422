import api from "@/services/api";
import { CustomField, CreateCustomField, UpdateCustomField, UpdateOrCreateCustomFieldValues } from "@/services/types";
import { SourceType } from "@/services/enums";

export const customFieldsService = {
    async getCustomFields(sourceType: SourceType): Promise<CustomField[]> {
        const response = await api.get("/custom-fields", { params: { sourceType } });
        return response.data;
    },
    async createCustomField(payload: CreateCustomField): Promise<void> {
        const response = await api.post("/custom-fields", payload, { params: { sourceType: payload.sourceType } });
        return response.data;
    },
    async updateCustomField(id: number, payload: UpdateCustomField): Promise<void> {
        const response = await api.patch(`/custom-fields/${id}`, payload, { params: { sourceType: payload.sourceType } });
        return response.data;
    },
    async removeCustomField(id: number, sourceType: SourceType): Promise<void> {
        const response = await api.delete(`/custom-fields/${id}`, { params: { sourceType } });
        return response.data;
    },
    async updateOrCreateCustomFieldValues(payload: UpdateOrCreateCustomFieldValues, sourceType: SourceType) {
        return api.put(`/custom-fields/values/bulk`, payload, { params: { sourceType } });
    },
    async reorderFields(reordered: { id: number; newIndex: number }, sourceType: SourceType) {
        return (await api.patch(`/custom-fields/reorder/${reordered.id}`, reordered, { params: { sourceType } })).data;
    },
};
